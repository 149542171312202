@font-face {
    font-family: "OttoIconFonts";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/OTTO-Icons.woff2") format("woff2"),
    url("../fonts/OTTO-Icons.woff") format("woff"),
    url("../fonts/OTTO-Icons.ttf") format("truetype");
}

@font-face {
    font-family: "OttoIcons";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/OTTO-Icons.woff2") format("woff2"),
    url("../fonts/OTTO-Icons.woff") format("woff"),
    url("../fonts/OTTO-Icons.ttf") format("truetype");
}
