@use "~@otto-ec/global-frontend-settings/gfs" as *;

@font-face {
  font-family: "OttoSans";
  font-style: normal;
  font-weight: normal;
  src:
    url("../fonts/otto-sans.woff2") format("woff2"),
    url("../fonts/otto-sans.woff") format("woff");
}

@font-face {
  font-family: "OttoSans";
  font-style: normal;
  font-weight: bold;
  src:
    url("../fonts/otto-sans-bold.woff2") format("woff2"),
    url("../fonts/otto-sans-bold.woff") format("woff");
}

@include from-l() {
  @font-face {
    font-family: "OttoSansThin";
    font-style: normal;
    font-weight: normal;
    src:
      url("../fonts/otto-sans-thin.woff2") format("woff2"),
      url("../fonts/otto-sans-thin.woff") format("woff");
  }
}
